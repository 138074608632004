import React from 'react'
import SkillsBody from '../SkillsBody'
import SkillsHeader from '../SkillsHeader'

function Skills() {
  return (
    <>
      <SkillsHeader></SkillsHeader>
      <SkillsBody></SkillsBody>
    </>
  )
}

export default Skills