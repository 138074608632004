import React from 'react'
import ProjectsHeader from '../ProjectsHeader'
import ProjectsBody from '../ProjectsBody'

function Projects() {
  return (
    <>
    <ProjectsHeader></ProjectsHeader>
    <ProjectsBody></ProjectsBody>
    </>
  )
}

export default Projects