import React from 'react'

function SkillsHeader() {
    return (
        <div className="Header">
            <h1 className="title-h1">Skills</h1>
            <h2 className="title-h2">Skills I have acquired over time</h2>
            <div className="row">
            </div>
        </div>
    )
}

export default SkillsHeader